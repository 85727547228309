<template>
  <div>
    <CRow v-if="showLoader" class="justify-content-center">
      <Loader
        width="100px"
        height="100px"
        fill-color="#c2c2c2"
        cssClass="fadeIn anim-speed--1s"
      />
    </CRow>
    <CRow v-if="!showLoader" class="fadeIn anim-speed--2s">
      <CCol sm="12">
        <CCard>
          <CCardHeader
            class="flex flex--space-between flex--align-items-baseline"
          >
            <!-- <h4 class="card-title">{{ messages.closureForecast.title }}</h4> -->
            <span class="col-xs-12 col-md-9 mb-2 mb-md-0 p-0">
              <strong>{{ messages.tables.results }} </strong>
              <slot name="area"></slot>
              <slot name="period"></slot> /
              <slot name="fiscalYear"></slot>
            </span>
            <ExportExcelButton
              :tableId="tableId"
              @export-table="exportTableToExcel($event)"
            />
          </CCardHeader>
          <CCardBody>
            <CDataTable
              :fields="fields"
              :items="items"
              :ref="tableId"
              :noItemsView="{
                noItems: messages.tables.noItems,
                noResults: messages.tables.noResults,
              }"
              outlined
              border
              clickableRows
              hover
              add-table-classes="closure-forecast"
            >
              <template #thead-top>
                <tr class="dark-gray-2">
                  <th v-for="(cell, i) in thead" :key="i" :colspan="cell.span">
                    {{ cell.name.toUpperCase() }}
                  </th>
                </tr>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
const Loader = () =>
  import(/* webpackChunkName: "loader" */ "@/components/atoms/Loader");
import ExportHTML2Excel from "@/helpers/ExportHTML2Excel";
const ExportExcelButton = () =>
  import(
    /* webpackChunkName: "exportExcelButton" */ "@/components/molecules/ExportExcelButton"
    );
export default {
  name: "TableClosureForecast",
  components: {
    Loader,
    ExportExcelButton,
  },
  props: {
    fields: {
      type: Array,
      required: false,
    },
    items: {
      type: Array,
      required: false,
    },
    thead: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      showLoader: false,
      tableId: "closureForecast"
    };
  },
  computed: {
    messages() {
      return this.$store.getters.getLangMessages;
    },
  },
  methods: {
    exportTableToExcel() {
      ExportHTML2Excel(this.$el.querySelector("table"), this.messages.closureForecast.title);
    },
  },
};
</script>
<style lang="scss">
.table {
  &.closure-forecast {
    td,
    th {
      min-width: 10rem !important;
    }
    thead {
      th {
        text-align: center !important;
      }
    }
  }
}
</style>
